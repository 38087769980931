<template>
  <div
    class="pageContainer app-container studentContainer"
    style="position: relative"
  >
    <el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
      <el-tab-pane label="" name="td">
        <span slot="label">
          <i>校友问答</i>
        </span>
      </el-tab-pane>
    </el-tabs>
    <el-row>
      <el-input v-model="formData.stu_name" size="small" placeholder="创建人" style="max-width: 200px; margin-right: 10px;"/>
      <el-input v-model="formData.issue_name" size="small" placeholder="问题标题" style="max-width: 200px; margin-right: 10px; margin-left: 10px"/>

      <el-select v-model="formData.issue_type" size="small" placeholder="问题类型" style="max-width: 200px; margin-right: 10px; margin-left: 10px">
        <el-option label="工作" value="工作"></el-option>
        <el-option label="生活" value="生活"></el-option>
        <el-option label="教程" value="教程"></el-option>
      </el-select>
<!--      <el-select-->
<!--        v-model="formData.status"-->
<!--        placeholder="请选择审核状态"-->
<!--        style="max-width: 200px; margin-right: 10px; margin-left: 10px"-->
<!--      >-->
<!--        <el-option label="待审核" value="0"></el-option>-->
<!--        <el-option label="未通过" value="-1"></el-option>-->
<!--        <el-option label="通过" value="1"></el-option>-->
<!--      </el-select>-->
      <el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询</el-button>
      <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning"
      >重置</el-button>
    </el-row>
    <el-table stripe :data="dataList" style="width: 100%; margin: 20px 0; color: #000" border size="mini">
      <el-table-column prop="issue_name" label="标题" width="150"></el-table-column>

      <el-table-column prop="issue_content" label="内容" min-width="120"></el-table-column>
      <el-table-column prop="issue_type" label="类型" width="60"></el-table-column>
      <el-table-column prop="scope" label="是否公开" width="70">
        <template slot-scope="scope">
          <span v-if="scope.row.scope == '1'">不公开</span>
          <span v-if="scope.row.scope == '0'">公开</span>
        </template>
      </el-table-column> <el-table-column prop="location" label="学生位置" width="250"></el-table-column>

      <el-table-column prop="stu_name" label="学生姓名" width="80"></el-table-column>
      <el-table-column prop="phone" label="学生电话" width="100"></el-table-column>
      <el-table-column prop="cdate" label="发布时间" width="150"></el-table-column>
<!--      <el-table-column prop="status" label="审核状态" min-width="120">-->
<!--        <template slot-scope="scope">-->
<!--          <span v-if="scope.row.status == '1'">通过</span>-->
<!--          <span v-if="scope.row.status == '-1'">未通过</span>-->
<!--          <span v-if="scope.row.status == '0'">待审核</span>-->
<!--        </template>-->
<!--      </el-table-column>-->

<!--      <el-table-column prop="reviewer" label="审核人" min-width="120"></el-table-column>-->
<!--      <el-table-column prop="remark" label="评语" min-width="120"></el-table-column>-->
<!--      <el-table-column fixed="right" label="操作" width="150">-->
<!--        <template slot-scope="scope">-->
<!--          <div class="flexStart">-->
<!--            <el-button type="text" size="small" @click="review(scope.row)" style="margin: 0 !important">审核</el-button>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <el-dialog title="问题审核" :visible.sync="dialogVisible" width="30%">
      <el-form ref="form" :model="ruleForm" label-width="80px">
        <el-form-item label="批语">
          <el-input
            type="textarea"
            v-model="ruleForm.remark"
            placeholder="批语"
          ></el-input>
        </el-form-item>
        <el-form-item label="审核状态">
          <el-select v-model="ruleForm.status" placeholder="审核状态">
            <el-option label="待审核" :value="0">待审核</el-option>
            <el-option label="通过" :value="1">通过</el-option>
            <el-option label="不通过" :value="-1">不通过</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="current_page"
        :page-size="per_page"
        layout="total, prev, pager, next"
        :total="count"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      count: 0,
      current_page: 1,
      per_page: 20,
      activeName: "td",
      dataList: [],
      formData: {},
      ruleForm: {
        id: "",
        remark: "",
        status: "",
      },
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
  },
  methods: {
    getlist() {
      this.$http
        .post("api/sys_forum_list", {
          page: this.current_page,
          pagesize: this.per_page,
          boole: false,
          stu_name: this.formData.stu_name,
          issue_name: this.formData.issue_name,
          issue_type: this.formData.issue_type,
          status: this.formData.status,
          type:1,
        })
        .then((res) => {
          if(res.data.data&&res.data.data.length>0){
            res.data.data.map(a=>{
              if(a.location.indexOf('location')!=-1){
                let location = JSON.parse(a.location)
                a.location = location.location=='请选择'?'':location.location
              }
            })
          }
          this.dataList = res.data.data;
          this.count = res.data.page.count;
          this.current_page = res.data.page.current_page;
          this.per_page = res.data.page.per_page;
        });
    },
    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.formData = {};
      this.getlist();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getlist();
    },
    review(e) {
      this.ruleForm.id = e.id;
      this.ruleForm.status = e.status;
      this.ruleForm.remark = e.remark;
      this.dialogVisible = true;
    },
    onSubmit() {
      this.$http
        .post("api/forum_check", {
          id: this.ruleForm.id,
          remark: this.ruleForm.remark,
          status: this.ruleForm.status,
        })
        .then((res) => {
          this.dialogVisible = false;
          this.getlist();
        });
    },
  },
};
</script>


